<template>
  <div class="contaner">
    <div @click="mineMessage" class="messageTitle12">
      <img class="messageIcon" src="../../assets/userPhone1.png" />
      <div>联系客服</div>
    </div>
    <!-- 激活股东 -->
    <div class="content">
      <div class="flow_content">
        <div class="flow_list">
          <div class="flow_header">
            <div class="flow_box" style="color:#FF5E5E;border:0.5px solid #FF5E5E">1</div>
            <div class="line_flow" style="border:0.5px dashed #FF5E5E"></div>
          </div>
          <div>
            <div style="display:flex">
              <div class="flow_title" style="color:#FF5E5E">兑换提交</div>
              <img class="flow_imgBtn" src="../../assets/successIcon2.png" />
            </div>
            <div class="flow_text1" style="color:#FF5E5E"></div>
            <div class="flow_text1" style="color:#909090">兑换工分:{{tableData.shares_amount}}</div>
            <div class="flow_text1" style="color:#2A2828" v-if="tableData.apply_type==3">增值费：{{tableData.cash_amount}}</div>
            <div class="flow_text1" style="color:#2A2828">运费：￥{{freightData.shares_apply_shipping_fee}}</div>
            <div class="flow_text1" style="color:#2A2828">支付状态：{{tableData.pay_at?'已完成':'待支付'}} <span class="payBtn" @click="payBtn" v-if="!tableData.pay_at">去支付</span></div>
            <div class="flow_text1" style="color:#909090" v-if="tableData.pay_info">提交时间:{{tableData.pay_at}}</div>
          </div>
        </div>
        <div class="flow_list">
          <div class="flow_header">
            <div class="flow_box" :style="tableData.audit_state>=1?'color:#FF5E5E;border:0.5px solid #FF5E5E':''">2</div>
            <div class="line_flow" :style="tableData.audit_state>=1?'border:0.5px dashed #FF5E5E':''"></div>
          </div>
          <div>
            <div style="display:flex">
              <div class="flow_title" :style="tableData.audit_state>=1?'color:#FF5E5E':''">平台审核</div>

              <img v-if="tableData.audit_state==3" class="flow_imgBtn" src="../../assets/failIcon.png" />
              <img v-if="tableData.audit_state==2" class="flow_imgBtn" src="../../assets/successIcon2.png" />
            </div>
            <!-- <div class="flow_text1" v-if="tableData.audit_state==2" style="color:#000">等待平台审核</div> -->
            <div class="flow_text1" v-if="tableData.audit_state==2" style="color:#07C160">审核通过</div>
            <div class="flow_text1" v-if="tableData.audit_state==1">等待平台审核</div>
            <div v-if="tableData.audit_state==3">
              <div class="flow_text1" style="color:#FF5E5E">审核已拒绝</div>
              <div class="flow_text1" style="color:#FF5E5E">原因:{{(tableData.audit_remark?tableData.audit_remark.remark:'')||(tableData.info_remark?tableData.info_remark[0]:'')}}</div>
            </div>
            <div class="flow_text1" style="color:#909090" v-if="tableData.audit_state>1"> 审核时间：{{tableData.audit_at}}</div>
          </div>
        </div>
        <div class="flow_list" v-if="!(tableData.audit_state==3)">
          <div class="flow_header">
            <div class="flow_box" :style="tableData.audit_state>=2?'color:#FF5E5E;border:0.5px solid #FF5E5E':''">3</div>
            <div class="line_flow" :style="tableData.audit_state>=2?'border:0.5px dashed #FF5E5E':''"></div>
          </div>
          <div>
            <div style="display:flex">
              <div class="flow_title" :style="tableData.audit_state>=2?'color:#FF5E5E':''">物料邮寄</div>
              <img class="flow_imgBtn" v-if="tableData.shipments" src="../../assets/successIcon2.png" />
            </div>
            <div class="flow_text1" v-if="!tableData.shipments&&tableData.audit_state>=2">等待平台制作物料</div>
            <div class="flow_text1" v-if="tableData.audit_state==2&&tableData.shipments" @click="logistics1">查看物流></div>
          </div>
        </div>
        <div class="flow_list" v-if="tableData.audit_state!=3">
          <div class="flow_header">
            <div class="flow_box" :style="tableData.shipments?'color:#FF5E5E;border:0.5px solid #FF5E5E':''">4</div>
          </div>
          <div>
            <div style="display:flex">
              <div class="flow_title" :style="tableData.shipments?'color:#FF5E5E':''">已完成</div>
              <img class="flow_imgBtn" v-if="tableData.shipments" src="../../assets/successIcon2.png" />
            </div>
          </div>
        </div>

        <div class="flow_list" v-if="tableData.audit_state==3">
          <div class="flow_header">
            <!-- <div class="flow_box" style="color:#FF5E5E;border:0.5px solid #FF5E5E" v-if="tableData.audit_state==3">4</div> -->
            <div class="flow_box" style="color:#FF5E5E;border:0.5px solid #FF5E5E" v-if="tableData.audit_state==3">3</div>
          </div>
          <div>
            <div style="display:flex">
              <div class="flow_title" style="color:#FF5E5E">已结束</div>
            </div>

          </div>
        </div>
      </div>

    </div>

    <div class="flow_popbox" v-if="popShow">
      <div class="flow_popbox">
        <div class="flow_pop1" v-if="popShow1">
          <img @click="submitBtn" style="width:10px;height:10px;" src="../../assets/cloneBtn2.png" />
          <div class="pop1">
            <div v-if="tableData.shipments" class="receiver">
              <div style="display:flex;margin-bottom:20px"><span class="title_pop">寄出时间:</span>{{tableData.shipments[0].create_time}}</div>
              <div style="display:flex;margin-bottom:20px"><span class="title_pop">快递公司:</span>{{tableData.shipments[0].shipments_info.company}}</div>
              <div style="display:flex;margin-bottom:20px"><span class="title_pop">快递单号:</span><span class="address_text_flow">{{tableData.shipments[0].shipments_info.order_sn}}</span></div>
            </div>
            <div style="display:flex;color: #707070FF"><span class="title_pop">收件人:</span>成先生</div>
            <div style="display:flex;color: #707070FF"><span class="title_pop">收件人电话:</span>16602728855</div>
            <div style="display:flex;color: #707070FF"><span class="title_pop">收件地址:</span><span class="address_text_flow">武汉市江岸区金桥大道特113号长江传媒大厦13楼</span></div>
            <div v-if="tableData.shipments">
              <div style="color:#FF5E5E">*请将收到的全部物料按要求填写完毕寄回。</div>

              <div class="formList_flow">
                <div class="offine_title_flow">快递公司:</div>
                <input v-model="dataForm.company.value" />
              </div>
              <div class="formList_flow">
                <div class="offine_title_flow">快递单号:</div>
                <input v-model="dataForm.order_sn.value" />
              </div>
              <div class="formList_flow">
                <div class="offine_title_flow">备注:</div>
                <textarea v-model="dataForm.message.value"></textarea>
              </div>
              <div class="submitBtn" @click="companyBtn">提交</div>
            </div>
          </div>
        </div>
        <div v-if="popShow3" class="shipper_box">

          <img @click="submitBtn" class="cloneBtn2" style="width:10px;height:10px;" src="../../assets/cloneBtn2.png" />
          <div class="loginBtn1">
            <div style="text-align:right;width:100px;margin-right:30px">物流单号:</div>
            <div>{{dataList.LogisticCode}}</div>
          </div>
          <div class="loginBtn1">
            <div style="text-align:right;width:100px;margin-right:30px">收件人:</div>
            <div>{{tableData.shipments.receiver_name||tableData.shipments.shipments_info.name}}</div>
          </div>
          <div class="loginBtn1">
            <div style="text-align:right;width:100px;margin-right:30px">收件人地址:</div>
            <div>{{tableData.shipments.shipments_info.address}}</div>
          </div>
          <div class="steps1" style="color:#000" v-if="dataList.State==3">
            <div>
              <div style="margin-left:-10px" class="boxcolor3">收</div>
              <div class="linecolor1"></div>
            </div>
            <div class="neth">
              <div style="color:#00000080">[收货地址]{{tableData.shipments.shipments_info.address}}</div>
              <!-- <div>{{endData.express.Traces[0].AcceptStation}}</div> -->
            </div>
          </div>
          <div class="steps1" v-for="(item,index) in dataList.Traces" v-bind:key="index">
            <div v-if="index!=0">
              <div class="boxcolor2"></div>
              <div class="linecolor1"></div>
            </div>
            <div v-else>
              <div class="boxcolor1"></div>
              <div class="linecolor1"></div>
            </div>
            <div :class="index==0&&item.Action!=3?'neth colorText':'neth'">
              <div v-if="item.Action==1">以揽件{{item.AcceptTime}}</div>
              <div v-if="item.Action==2||item.Action==201">运输中{{item.AcceptTime}}</div>
              <div v-if="item.Action==202">派送中{{item.AcceptTime}}</div>
              <div v-if="item.Action==211">待取件{{item.AcceptTime}}</div>
              <div v-if="item.Action==3">已签收{{item.AcceptTime}}</div>
              <div>{{item.AcceptStation}}</div>
            </div>
          </div>
          <div class="steps1" v-if="dataList.State==2">
            <div>
              <div :class="dataList.State==0?'boxcolor1':'boxcolor2'"></div>
              <div class="linecolor1"></div>
            </div>
            <!-- <div class="neth" :style="dataList.State==0?'color:#000':''">
                    <div>已发货{{tableData.update_time}}</div>
                    <div>等待揽收中</div>
                  </div> -->
          </div>
          <div class="steps1">
            <div>
              <div class="boxcolor2"></div>
            </div>
            <div class="neth">
              <div>已下单{{dataList.pay_at}}</div>

            </div>
          </div>
          <!-- <div class="order" v-if="tableData.shipments&&!tableData.shipments.receive_at" @click="getOrder">确认收货</div> -->
        </div>
      </div>
    </div>
    <div class="error_text" v-if="tableData.audit_state==3">*如果审核失败，存在已支付金额将由财务1~2个工作日原路退回详情进度请查看<div class="return" @click="returnBtn">退货列表></div></div>
    <toast v-if="toastShow" :config="configData"></toast>
  </div>
</template>
<script>
import toast from "@/components/toast";
// import wx from "weixin-js-sdk";
// import logistics from "@/components/logistics";
import {
  // shipments_api,
  conversionDetails_api,
  getConfig_api
} from "@/api/deal";
import { getShipments_api } from "@/api/admin";
import { Toast } from "vant";
export default {
  data() {
    return {
      queryList: null,
      tableData: {
        activate: {},
        shipments: {
          shipments_info: {}
        }
      },
      popShow: false,
      popShow1: false,
      popShow2: false,
      popShow3: false,
      dataForm: {
        company: {
          value: "",
          title: "快递公司",
          text: "请输入快递公司",
          color: "#DEB77A"
        },
        order_sn: {
          value: "",
          title: "快递单号",
          text: "请输入快递单号",
          color: "#DEB77A"
        },
        message: {
          value: "",
          title: "备注",
          text: "请输入备注",
          color: "#DEB77A"
        }
      },
      configData: {
        title: ""
      },
      toastShow: false,
      dataList: {
        activate: {}
      },
      details: "",
      payShow: true,
      freightData: {}
    };
  },
  components: {
    toast
    // logistics
  },
  created() {
    document.title = "兑换详情";
    this.queryList = this.$route.query;
    this.getDetails();
    // 获取运费
    this.getfreight();
  },

  methods: {
    getOrder() {},
    returnBtn(){
      this.$router.push("../deal/return")
    },
    getfreight() {
      let data = {
        config: ["shares_apply_shipping_fee"]
      };
      getConfig_api(data).then(res => {
        if (res.code == 0) {
          this.freightData = res.data;
        }
      });
    },
    lookAddress() {
      this.popShow = true;
      this.popShow1 = true;
    },
    logistics1() {
      console.log(1);
      if (
        !this.tableData.shipments.shipments_info ||
        this.dataList.Traces.length == 0
      ) {
        Toast({
          message: "暂无物流信息",
          icon: "none",
          duration: 1000
        });
        return false;
      }
      this.popShow = true;
      this.popShow3 = true;
      this.$forceUpdate();
    },
    submitBtn() {
      this.popShow = false;
      this.popShow1 = false;
      this.popShow2 = false;
      this.popShow3 = false;
      this.$forceUpdate();
    },
    mineMessage() {
      // wx.closeWindow();
      this.$router.push("../lador/service");
    },
    getDetails() {
      conversionDetails_api(this.queryList.id).then(res => {
        if (res.code == 0) {
          this.tableData = res.data;
          if (this.tableData.shipments) {
            this.logistics();
          }
        }
      });
      //   let res = await apiCommon.getUserInfo_api();
      //   if (res.code == 0) {
      //     console.log(res.data);
      //     this.tableData = res.data;

      this.$forceUpdate();
    },
    logistics() {
      console.log(21);
      if (this.tableData.shipments) {
        this.tableData.shipments.shipments_info.logistic = 0;
        getShipments_api(this.tableData.shipments.shipments_info).then(res => {
          console.log(res.data);
          if (res.code == 0) {
            this.dataList = res.data;
            if (this.dataList) {
              this.dataList.Traces.reverse();
            }
            console.log(this.dataList);
            this.$forceUpdate();
          }
        });
      }
    },
    // 发货
    // companyBtn() {
    //   for (var i in this.dataForm) {
    //     if (!this.dataForm[i]) {
    //       return false;
    //     }
    //   }
    //   let data = {
    //     audit_id: Number(this.queryList.id),
    //     audit_type: 3,
    //     shipments_info: {
    //       company: this.dataForm.company.value,
    //       order_sn: this.dataForm.order_sn.value,
    //       message: this.dataForm.message.value
    //     }
    //   };
    //   shipments_api(data).then(res => {
    //     console.log(res.data);
    //     if (res.code == 0) {
    //       this.toastShow = true;
    //       this.configData.title = "操作成功";
    //       setTimeout(() => {
    //         this.toastShow = false;
    //       }, 1000);
    //       this.popShow = false;
    //       this.popShow1 = false;
    //       this.popShow2 = false;
    //       this.popShow3 = false;
    //       location.reload();
    //     }
    //   });
    // },
    async payBtn() {
      this.$router.push(`./pay?id=${this.tableData.apply_id}`);
      // Toast.loading({
      //   message: '加载中...',
      //   forbidClick: true,
      // });
      // let res1 = await apiCommon.getConfig();
      // console.log(res1);
      // let tableData = res1.data;
      // wx.config(tableData);
      // if (res1.code == 0) {
      //   if(this.payShow==false){
      //     return false;
      //   }
      //   this.payShow = false;
      //   let res = await apiCommon.putSharePay_api(this.tableData.apply_id);
      //   this.payShow = true;
      //   let that = this;
      //   if (res.code == 0) {
      //     wx.chooseWXPay({
      //       timestamp: res.data.pay_info.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
      //       nonceStr: res.data.pay_info.nonceStr, // 支付签名随机串，不长于 32 位
      //       package: res.data.pay_info.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
      //       signType: "MD5", // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
      //       paySign: res.data.pay_info.paySign, // 支付签名
      //       success(res) {
      //         if (res.errMsg == "chooseWXPay:ok") {
      //           that.$toast("支付成功");
      //           that.getDetails(that.tableData.apply_id);
      //         } else {
      //           alert(res.errMsg);
      //           that.$toast("支付失败", "middle");
      //         }
      //       },
      //       cancel: function(res) {
      //         that.$toast("支付取消", "middle", res);
      //       }
      //     });
      //   }
      // }
      // let tableData = res1.data;
    }
  }
};
</script>
<style type="text/css">
.van-toast {
  width: 300px;
  min-height: 120px;
}
.van-toast__text {
  font-size: 35px;
}
.return{
  display: inline-block;
  color:#1980E9;
  margin-left:10px
}
.van-icon {
  font-size: 40px;
}
.van-dialog {
  width: 500px;
  font-size: 35px !important;
}
.van-dialog__message {
  font-size: 25px;
  line-height: 40px;
}
.error_text{
  width:640px;
  margin: 50px auto;
  font-size: 30px
}
.order {
  width: 115px;
  height: 52px;
  background: #ffffff;
  border: 2px solid #ff5e5e;
  opacity: 1;
  border-radius: 5px;
  text-align: center;
  line-height: 51px;
  font-size: 25px;
  color: #ff5e5e;
  margin-left: 70px;
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.infoBox {
  width: 690px;
  min-height: 400px;
  margin: 300px auto 0;
  background-color: #fff;
  font-size: 25px;
  padding: 50px;
  box-sizing: border-box;
}
.infoBox img {
  width: 200px;
  height: 200px;
  margin-left: 200px;
}
.messageTitle12 .messageIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.messageTitle12 {
  text-align: right;
  margin-right: 20px;
  font-size: 20px;
  margin-top: 20px;
  color: #000;
  position: absolute;
  top: 10px;
  right: 10px;
}

.title_share {
  font-size: 35px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #deb77a;
  opacity: 1;
  margin-top: 70px;
}

.content {
  margin-left: 75px;
  font-size: 25px;
  color: #000;
  margin-top: 103px;
}
.content > div {
  margin-bottom: 90px;
}
.colorRed {
  color: #fc3757;
  margin: 20px 0;
}
.colorText {
  color: #4b93ffff;
  font-size: 20px;
}
.flow_popbox {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}
.flow_pop1 {
  width: 600px;
  /* height:700px; */
  background-color: #fff;
  margin: 200px auto 0;
  /* text-align:right; */
  padding: 100px 20px 20px;
  box-sizing: border-box;
  position: relative;
}
.flow_pop1 img {
  position: absolute;
  top: 20px;
  right: 20px;
}
.pop1 {
  font-size: 22px;
}
.pop1 > div {
  margin: 20px 60px;
  text-align: none;
}
.title_pop {
  width: 130px;
  display: inline-block;
  color: #707070ff;
}
.pop3 > div {
  margin-top: 20px;
}
.formList_flow {
  display: flex;
  margin: 20px 0;
}
.offine_title_flow {
  width: 100px;
  font-size: 22px;
  color: #707070;
  margin: 0;
}
.pop1 .submitBtn {
  width: 160px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 25px;
  border: 1px solid #ff5e5e;
  color: #ff5e5e;
  margin: 20px auto 0;
}
.address_text_flow {
  width: 300px;
}
.pop2 > div {
  font-size: 26px;
  color: #707070;
  margin: 20px 0;
}
.pop2 {
  margin-left: 80px;
}
.selectColor {
  color: #ff5e5e;
  border: 1px solid #ff5e5e;
}
.selectLine {
  border: 1px dashed #ff5e5e;
}
.shipper_box {
  width: 700px;
  height: 800px;
  background-color: #fff;
  margin: 200px auto 0;
  font-size: 20px;
  color: #00000080;
  overflow-y: scroll;
  position: relative;
  padding: 50px;
  box-sizing: border-box;
}
.flow_text {
  font-size: 25px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #deb77a;
  text-align: right;
  margin: 25px 0;
}
.flow_contanter {
  padding: 0 38px;
  box-sizing: border-box;
}
.userBtn {
  float: right;
  text-align: center;
}
.userBtn img {
  width: 36px;
  height: 36px;
}
.userBtn div {
  font-size: 15px;
  color: #000;
}
.flow_box {
  width: 37px;
  height: 37px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 37px;
  color: #737373;
  opacity: 1;
  text-align: center;
  border: 2px solid #737373;
  border-radius: 50%;
}
.flow_content {
  margin-top: 100px;
}
.line_flow {
  width: 1px;
  border: 1px dashed #737373;
  margin: 0 18px;
  height: 100px;
}
.flow_list {
  display: flex;
  padding: 30px auto;
}
.flow_imgBtn {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin: auto 0;
}
.flow_title {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: rgba(115, 115, 115, 0.5);
  margin: 0 10px;
}
.flow_text1 {
  margin-left: 10px;
  font-size: 20px;
  color: #4b93ff;
  margin-top: 15px;
}
.flow_popbox {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}
.flow_pop1 {
  width: 600px;
  /* height:700px; */
  background-color: #fff;
  margin: 200px auto 0;
  /* text-align:right; */
  padding: 100px 20px 20px;
  box-sizing: border-box;
  position: relative;
}
.flow_pop1 img {
  position: absolute;
  top: 20px;
  right: 20px;
}
.pop1 {
  font-size: 22px;
}
.pop1 > div {
  margin: 20px 60px;
  text-align: none;
}
.title_pop {
  width: 130px;
  display: inline-block;
  color: #707070ff;
}
.pop3 > div {
  margin-top: 20px;
}
.formList_flow {
  display: flex;
}
.offine_title_flow {
  width: 100px;
  font-size: 22px;
  color: #707070;
  margin: 0;
}
.pop1 .submitBtn {
  width: 160px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 25px;
  border: 1px solid #ff5e5e;
  color: #ff5e5e;
  margin: 20px auto 0;
}
.address_text_flow {
  width: 300px;
}
.pop2 > div {
  font-size: 26px;
  color: #707070;
  margin: 20px 0;
}
.pop2 {
  margin-left: 80px;
}
.selectColor {
  color: #ff5e5e;
  border: 1px solid #ff5e5e;
}
.selectLine {
  border: 1px dashed #ff5e5e;
}
@step-icon-size {
  font-size: 40px;
}
.loginBtn1 {
  margin: 20px 0;
  display: flex;
  /* margin-left: 71px */
}
.loginBtn1 > div {
  /* display: flex; */
  /* margin-left: 77px; */
}
.loginBtn1 span {
  margin: 0 5px;
}
.comfigBtn {
  width: 124px;
  height: 40px;
  background-color: #4b93ff;
  border-radius: 5px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0px;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  margin-left: 79px;
}
.getAddress {
  display: flex;
  margin-left: 70px;
  margin-top: 30px;
}
.steps1 {
  /* height: 200px; */
  display: flex;
  margin-left: 60px;
}
.steps1 .colorText {
  color: #000000;
}
.steps1 .neth div {
  margin: 5px 0;
}
.neth {
  padding: 5px 0 20px 10px;
}
.linecolor {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px dashed #ff5e5e;
  margin-left: 18.5px;
}
.linecolor1 {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px solid #d1d1d1;
  margin-left: 6.5px;
}
.linecolor2 {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px solid #d1d1d1;
  margin-left: 6.5px;
}
.boxcolor {
  width: 37px;
  height: 37px;
  background: #ffffff;
  border: 2px solid #ff5e5e;
  border-radius: 50%;
  opacity: 1;
  text-align: center;
  line-height: 37px;
  color: #ff5e5e;
  font-size: 22px;
}
.boxcolor1 {
  width: 13px;
  height: 13px;
  background: #ff5e5e;
  border-radius: 50%;
  opacity: 1;
}
.boxcolor2 {
  width: 13px;
  height: 13px;
  background: #d1d1d1;
  border-radius: 50%;
  opacity: 1;
}
.boxcolor3 {
  width: 44px;
  height: 44px;
  background: #fc6305;
  border-radius: 50%;
  opacity: 1;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 44px;
}
.time_text {
  font-size: 20px;
  color: #000;
  margin: 10px 0 20px 10px;
}
.pop3 {
  height: 776px;
  background: #ffffff;
  font-size: 16px;
  color: #707070;
  padding: 20px 20px 20px 50px;
}
.payClass {
  color: #4b93ff;
  font-size: 25px;
  margin-left: 10px;
}
.shipper_box {
  width: 700px;
  height: 800px;
  background-color: #fff;
  margin: 200px auto 0;
  font-size: 20px;
  color: #00000080;
  overflow-y: scroll;
  position: relative;
}
.flow_text {
  font-size: 25px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #deb77a;
  text-align: right;
  margin: 25px 0;
}
.flow_contanter {
  padding: 0 38px;
  box-sizing: border-box;
}
.userBtn {
  float: right;
  text-align: center;
}
.userBtn img {
  width: 36px;
  height: 36px;
}
.userBtn div {
  font-size: 15px;
  color: #000;
}
.flow_box {
  width: 37px;
  height: 37px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 37px;
  color: #737373;
  opacity: 1;
  text-align: center;
  border: 2px solid #737373;
  border-radius: 50%;
}
.flow_content {
  margin-top: 100px;
}
.line_flow {
  width: 1px;
  border: 0.5px dashed #737373;
  margin: 0 18px;
  height: 200px;
}
.flow_list {
  display: flex;
  padding: 30px auto;
}
.flow_imgBtn {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin: auto 0;
}
.flow_title {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: rgba(115, 115, 115, 0.5);
  margin: 0 10px;
}
.flow_text1 {
  margin-left: 10px;
  font-size: 30px;
  color: #2A2828;
  margin-top: 15px;
}
.flow_popbox {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}
.flow_pop1 {
  width: 600px;
  /* height:700px; */
  background-color: #fff;
  margin: 200px auto 0;
  /* text-align:right; */
  padding: 100px 20px 20px;
  box-sizing: border-box;
  position: relative;
}
.flow_pop1 img {
  position: absolute;
  top: 20px;
  right: 20px;
}
.pop1 {
  font-size: 22px;
}
.pop1 > div {
  margin: 20px 60px;
  text-align: none;
}
.title_pop {
  width: 130px;
  display: inline-block;
}
.pop3 > div {
  margin-top: 20px;
}
.formList_flow {
  display: flex;
}
.offine_title_flow {
  width: 100px;
  font-size: 22px;
  color: #707070;
  margin: 0;
}
.pop1 .submitBtn {
  width: 160px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 25px;
  border: 1px solid #ff5e5e;
  color: #ff5e5e;
  margin: 20px auto 0;
}
.address_text_flow {
  width: 300px;
}
.pop2 > div {
  font-size: 26px;
  color: #707070;
  margin: 20px 0;
}
.pop2 {
  margin-left: 80px;
}
.selectColor {
  color: #ff5e5e;
  border: 0.5px solid #ff5e5e;
}
.selectLine {
  border: 0.5px dashed #ff5e5e;
}
@step-icon-size {
  font-size: 40px;
}
.title_text {
  color: #4b93ff;
  margin: 0px 0 0 20px !important;
  font-size: 24px;
}
.card {
  margin: 20px 0;
}
.success2 {
  width: 24px;
  height: 24px;
  margin: 10px 0 0 5px;
}
.messageIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.messageTitle1 {
  text-align: right;
  margin-right: 20px;
}
.loginBtn1 {
  margin: 20px 0;
  display: flex;
}
.loginBtn1 > div {
  /* display: flex; */
  /* margin-left: 77px; */
  margin-bottom: 20px;
}
.loginBtn1 span {
  margin: 0 5px;
}
.comfigBtn {
  width: 124px;
  height: 40px;
  background-color: #4b93ff;
  border-radius: 5px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0px;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  margin-left: 79px;
}
.getAddress {
  display: flex;
  margin-left: 70px;
  margin-top: 30px;
}
.steps1 {
  /* height: 200px; */
  display: flex;
  margin-left: 60px;
}
.steps1 .colorText {
  color: #000000;
}
.steps1 .neth div {
  margin: 5px 0;
}
.neth {
  padding: 5px 0 20px 10px;
}
.linecolor {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px dashed #ff5e5e;
  margin-left: 18.5px;
}
.linecolor1 {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px solid #d1d1d1;
  margin-left: 6.5px;
}
.linecolor2 {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px solid #d1d1d1;
  margin-left: 6.5px;
}
.boxcolor {
  width: 37px;
  height: 37px;
  background: #ffffff;
  border: 2px solid #ff5e5e;
  border-radius: 50%;
  opacity: 1;
  text-align: center;
  line-height: 37px;
  color: #ff5e5e;
  font-size: 22px;
}
.boxcolor1 {
  width: 13px;
  height: 13px;
  background: #ff5e5e;
  border-radius: 50%;
  opacity: 1;
}
.boxcolor2 {
  width: 13px;
  height: 13px;
  background: #d1d1d1;
  border-radius: 50%;
  opacity: 1;
}
.boxcolor3 {
  width: 44px;
  height: 44px;
  background: #fc6305;
  border-radius: 50%;
  opacity: 1;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 44px;
}
.cart {
  /* width: 120px; */
  /* height: 120px */
  /* margin: 190px auto 0; */
  text-align: center;
  margin-top: 50px;
}
.cart img {
  width: 100%;
  height: 100%;
}
.auditInfo {
  margin: 50px 0 0 37px;
}
.content1 {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.5);
  padding-top: 40px;
  box-sizing: border-box;
  width: 100%;
}
.steps {
  height: 150px;
  padding-left: 61px;
}
.step_text {
  font-size: 22px;
  margin: 52px 0 0 54px;
}
.van-step--vertical {
  font-size: 22px;
  color: #000000;
}
.van-step--vertical {
  /* height: 200px; */
  line-height: 45px;
}
.title1 {
  color: #ff5e5e;
}
.van-step__icon--active,
.van-step__icon--finish,
.van-step__title--active,
.van-step__title--finish {
  color: #ff5e5e;
}
.submit {
  width: 160px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #4b93ff;
  opacity: 1;
  border-radius: 5px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  opacity: 1;
  margin-left: 100px;
  margin-top: 20px;
}
.logistics {
  margin-left: 70px;
}
.cloneBtn2 {
  position: absolute;
  top: 20px;
  right: 20px;
}
.payBtn {
  display: inline-block;
  width: 130px;
  height: 60px;
  background: #2a2828;
  border-radius: 5px 5px 5px 5px;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  line-height:60px
}
</style>